
body { 
    margin:0px;
    -webkit-tap-highlight-color: transparent;
}

@font-face {
    font-family: 'short_filmsmedium';
    src: url('shortfilms-medium-webfont.woff2') format('woff2'),
         url('shortfilms-medium-webfont.woff') format('woff'),
         url('shortfilms-medium-webfont.ttf') format('truetype'),
         url('shortfilms-medium-webfont.svg#short_filmsmedium') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'narbut_classic';
    src: url('Narbut_Classic.ttf') format('truetype');
   
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'narbut_abetka';
    src: url('Narbut_Abetka.ttf') format('truetype');
   
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.pm-xword-body {touch-action: none;-ms-touch-action: none;-webkit-touch-callout:none;-webkit-user-select:none;-khtml-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}
.error-wrong-letter-bg {font-size: 16.6667px; left: 17px;top: 5px;position: relative;}
div.dict {color:black; font-size: 14px; }
div.dict a {color: maroon} /*#9c0c0e*/
ul.dictlist {list-style: circle;padding-inline-start: 40px; margin: 5px}
.FORMULA a{color: maroon}
a{color: maroon}
h4 {color: maroon}
#dictionary div.FORMULA {align: left; font-size: 16px; color: black; opacity: 60%;text-align: left;}

.B{display:inline;font-weight:bold;font-style:normal}.I{display:inline;font-style:italic;font-weight:normal}.S{display:inline;letter-spacing:5px;font-style:normal;font-weight:normal}.U{display:inline;text-decoration:underline}.N{display:inline;font-weight:normal;font-style:normal;letter-spacing:normal;text-decoration:none}.BI{display:inline;font-weight:bold;font-style:italic}.UX{display:inline;vertical-align:super;font-size:.8em;font-weight:inherit;font-style:inherit}.BUX{display:inline;vertical-align:super;font-size:.8em;font-weight:bold;font-style:normal}.IUX{display:inline;vertical-align:super;font-size:.8em;font-style:italic;font-weight:normal}.LX{display:inline;vertical-align:sub;font-size:.8em;font-weight:inherit;font-style:inherit}.ILX{display:inline;vertical-align:sub;font-size:.8em;font-style:italic;font-weight:normal}.BLX{display:inline;vertical-align:sub;font-size:.8em;font-style:normal;font-weight:bold}.COLORNEW{display:inline;background-color:aqua}.COLORCHG{display:inline;background-color:#ff0}.COLORNR{display:inline;background-color:#ffa500}.ENTRY{display:block;padding-top:5px;font-size:1em;color:#000}.DEL{display:block}
.WORD{display:inline;font-weight:bold;color: #9c0c0e;}
.LPART{display:inline;}.LPART>b{}.LINK{display:inline;font-style:italic}.LINKENTRY{margin-top:30px;border-top:1px solid grey}.LINKTXT{display:inline;font-weight:bold;font-style:normal}.ETYM{display:block;padding-top:25px;font-style:normal;font-weight:normal}.BLKF{display:inline}.BLKN{display:block;padding-top:25px}.Content>.BLKN{padding-top:0;display:inline}.BLKNUM{display:inline}.INTF{display:inline}.INTN{display:block;padding-top:25px}.INTNUM{display:inline}.INTVAR{display:inline;color:gray}.PARAM{display:inline;font-style:italic}.FORMULA{display:inline;font-style:normal;font-weight:normal}.SHD{display:inline}.SHDNUM{display:inline}.SBI{display:inline}.SBINUM{display:inline}
.ILL{display:inline;color: grey;}.ILLTXT{display:inline;font-style:italic}.ILLSRC{display:inline;font-style:normal}.ILLVAR{display:inline;color:gray}.PHRF{display:inline}.PHRN{display:block;padding-top:25px}.PHRSYM{display:inline;font-family:Arial Unicode MS}.PHRASE{display:inline}.PHRNUM{display:inline;font-weight:bold}.PHRTXT{display:inline;font-weight:bold}.DER{display:inline}.DERTXT{display:inline;font-weight:bold;font-style:italic}.REL{display:block;padding-top:25px;text-align:center;letter-spacing:5px;font-style:normal;font-weight:normal}.Node{margin-left:18px;zoom:1}.IsRoot{margin-left:0}.Expand{width:18px;height:18px;float:left}.Content{margin-left:18px;min-height:18px}.Content *{color:#000;padding-top:0}.Content>.WORD{color:#171567}.Content>.INTN{padding-top:0;display:inline}.ExpandOpen .Expand{background-image2:url2(../img/minus.gif)}.ExpandClosed .Expand{background-image2:url2(img/plus.gif)}.ExpandLeaf .Expand{background-image2:url2(img/leaf.gif)}.ExpandOpen .Container{display:block}.ExpandClosed .Container{display:none}.Tlum{display:inline}.Vidtinok{display:inline}.Slsp{display:inline}.Derivat{display:inline}.Illustr{display:inline}.Block{display:inline}.ExpandForILL{}

ul.music {margin: auto; max-width: 600px; margin-top:20px;padding-top:1px; list-style:disc url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);}
.music li.empty:before {color:maroon;position:relative;left:-0.5em;font-family:short_filmsmedium;}
.music li.full:before {color:maroon;position:relative;left:-0.5em;font-family:short_filmsmedium;}
.music li {margin-bottom:5px;}

#hint_text span:first-letter {font-weight: bold;display:inline-block;}
span#hint_next:hover {color: darkgrey;}
span#hint_back:hover {color: darkgrey;}
span#hint_next {color: black;opacity: 5%;}
span#hint_back {color: black;opacity: 5%;}
span.heart_first_empty:before { content:"♡";}
span.heart_first_empty:hover:before { content:"♥";}
span.heart_first_full:before { content:"♥";}
span.heart_first_full:hover:before { content:"♡";}
span.udar:before {content:"´";}


#aukrmova {
    font-family: 'narbut_abetka'; 
    text-decoration:none;
    font-size: 32.6667px; 
    color: #9c0c0e; 
}
/*background: -webkit-linear-gradient(0deg, maroon 0%, maroon 20%, maroon 90% ); */
#divukrmova {
    background: -webkit-linear-gradient(0deg, maroon 0%, maroon 20%, maroon 90% ); 
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#divukrmovaBlack {
    background: -webkit-linear-gradient(0deg, white 0%, white 20%, white 90% ); 
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.flower {
    filter: grayscale(100%);margin-left:2px;opacity:60%;font-weight: bold;font-size: 14px;color: maroon;
}
.resolved {
    filter: grayscale(50%);opacity:100%;
}
.flower_selected {
    filter: grayscale(0%);
}

.flower:hover {
  filter: grayscale(0);
}

@-webkit-keyframes image_blur {
    0% { -webkit-filter: blur(0.35px);}
    50% { -webkit-filter: blur(0.4px);}
    100% { -webkit-filter: blur(0.35px);}
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
    display: block;
    z-index: 50;
}

[data-tooltip] {
    display: inline-block;
    position: relative;
    cursor: context-menu;
    padding: 4px;
}
/* Tooltip styling */
[data-tooltip]:before {
    content: attr(data-tooltip);
    display: none;
    position: absolute;
    background: #000;
    color: #fff;
    padding: 4px 8px;
    font-size: 14px;
    line-height: 1.4;
    min-width: 100px;
    text-align: center;
    border-radius: 4px;
}
/* Dynamic horizontal centering */
[data-tooltip-position="top"]:before,
[data-tooltip-position="bottom"]:before {
    left: 50%;
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
/* Dynamic vertical centering */
[data-tooltip-position="right"]:before,
[data-tooltip-position="left"]:before {
    top: 50%;
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
[data-tooltip-position="top"]:before {
    bottom: 100%;
    margin-bottom: 6px;
}
[data-tooltip-position="right"]:before {
    left: 100%;
    margin-left: 6px;
}
[data-tooltip-position="bottom"]:before {
    top: 100%;
    margin-top: 6px;
}
[data-tooltip-position="left"]:before {
    right: 100%;
    margin-right: 6px;
}

/* Tooltip arrow styling/placement */
[data-tooltip]:after {
    content: '';
    display: none;
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}
/* Dynamic horizontal centering for the tooltip */
[data-tooltip-position="top"]:after,
[data-tooltip-position="bottom"]:after {
    left: 50%;
    margin-left: -6px;
}
/* Dynamic vertical centering for the tooltip */
[data-tooltip-position="right"]:after,
[data-tooltip-position="left"]:after {
    top: 50%;
    margin-top: -6px;
}
[data-tooltip-position="top"]:after {
    bottom: 100%;
    border-width: 6px 6px 0;
    border-top-color: #000;
}
[data-tooltip-position="right"]:after {
    left: 100%;
    border-width: 6px 6px 6px 0;
    border-right-color: #000;
}
[data-tooltip-position="bottom"]:after {
    top: 100%;
    border-width: 0 6px 6px;
    border-bottom-color: #000;
}
[data-tooltip-position="left"]:after {
    right: 100%;
    border-width: 6px 0 6px 6px;
    border-left-color: #000;
}
