
/* -------------------------------------
 * Bar container
 * ------------------------------------- */
.progress-radial {
  float: left;
  margin-right: 25px;
  margin-left: 25px;
  position: relative;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  border: 2px solid #2f3439;
  background-color: tomato;
}
.progress-radial .legend {
  color: black;
  padding-top: 130px;
  text-align: center;
  display: table;
  margin: 0 auto;
}

/* -------------------------------------
 * Optional centered circle w/text
 * ------------------------------------- */
.progress-radial .overlay {
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: #fffde8;
  border-radius: 50%;
  margin-left: 20px;
  margin-top: 20px;
  text-align: center;
  line-height: 60px;
  font-size: 16px;
}

/* -------------------------------------
 * Mixin for progress-% class
 * ------------------------------------- */
.progress-0 {
  background-image: linear-gradient(90deg, #2f3439 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(90deg, #B23232 50%, #2f3439 50%, #2f3439);
}

.progress-5 {
  background-image: linear-gradient(90deg, #2f3439 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(108deg, #B23232 50%, #2f3439 50%, #2f3439);
}

.progress-10 {
  background-image: linear-gradient(90deg, #2f3439 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(126deg, #B23232 50%, #2f3439 50%, #2f3439);
}

.progress-15 {
  background-image: linear-gradient(90deg, #2f3439 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(144deg, #B23232 50%, #2f3439 50%, #2f3439);
}

.progress-20 {
  background-image: linear-gradient(90deg, #2f3439 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(162deg, #B23232 50%, #2f3439 50%, #2f3439);
}

.progress-25 {
  background-image: linear-gradient(90deg, #2f3439 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(180deg, #B23232 50%, #2f3439 50%, #2f3439);
}

.progress-30 {
  background-image: linear-gradient(90deg, #2f3439 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(198deg, #B23232 50%, #2f3439 50%, #2f3439);
}

.progress-35 {
  background-image: linear-gradient(90deg, #2f3439 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(216deg, #B23232 50%, #2f3439 50%, #2f3439);
}

.progress-40 {
  background-image: linear-gradient(90deg, #2f3439 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(234deg, #B23232 50%, #2f3439 50%, #2f3439);
}

.progress-45 {
  background-image: linear-gradient(90deg, #2f3439 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(252deg, #B23232 50%, #2f3439 50%, #2f3439);
}

.progress-50 {
  background-image: linear-gradient(-90deg, #B23232 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(270deg, #B23232 50%, #2f3439 50%, #2f3439);
}

.progress-55 {
  background-image: linear-gradient(-72deg, #B23232 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(270deg, #B23232 50%, #2f3439 50%, #2f3439);
}

.progress-60 {
  background-image: linear-gradient(-54deg, #B23232 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(270deg, #B23232 50%, #2f3439 50%, #2f3439);
}

.progress-65 {
  background-image: linear-gradient(-36deg, #B23232 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(270deg, #B23232 50%, #2f3439 50%, #2f3439);
}

.progress-70 {
  background-image: linear-gradient(-18deg, #B23232 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(270deg, #B23232 50%, #2f3439 50%, #2f3439);
}

.progress-75 {
  background-image: linear-gradient(0deg, #B23232 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(270deg, #B23232 50%, #2f3439 50%, #2f3439);
}

.progress-80 {
  background-image: linear-gradient(18deg, #B23232 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(270deg, #B23232 50%, #2f3439 50%, #2f3439);
}

.progress-85 {
  background-image: linear-gradient(36deg, #B23232 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(270deg, #B23232 50%, #2f3439 50%, #2f3439);
}

.progress-90 {
  background-image: linear-gradient(54deg, #B23232 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(270deg, #B23232 50%, #2f3439 50%, #2f3439);
}

.progress-95 {
  background-image: linear-gradient(72deg, #B23232 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(270deg, #B23232 50%, #2f3439 50%, #2f3439);
}

.progress-100 {
  background-image: linear-gradient(90deg, #B23232 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0)), linear-gradient(270deg, #B23232 50%, #2f3439 50%, #2f3439);
}